// UserConfig.js

import React, { useState, useEffect } from 'react';
import { Save, Plus, X } from 'lucide-react'; // Importação de ícones
import { getAuth } from 'firebase/auth';
import axios from 'axios'; // Importado para requisições ao backend

const UserConfig = () => {
  // Estados para listagem de usuários
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersError, setUsersError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Estados para cadastro de novos usuários (Admin)
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newUserName, setNewUserName] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserProfile, setNewUserProfile] = useState('Atendente'); // Novo campo Perfil de Acesso
  const [newUserLoading, setNewUserLoading] = useState(false);
  const [newUserMessage, setNewUserMessage] = useState(null);

  // Estados para edição de um usuário específico
  const [editingUser, setEditingUser] = useState(null); // Objeto do usuário sendo editado
  const [editName, setEditName] = useState('');
  const [editPassword, setEditPassword] = useState('');
  const [editProfile, setEditProfile] = useState('Atendente'); // Novo campo Perfil de Acesso
  const [editLoading, setEditLoading] = useState(false);
  const [editMessage, setEditMessage] = useState(null);

  // Estado para simular ser admin (apenas para desenvolvimento)
  const [isAdmin, setIsAdmin] = useState(true); // Defina como 'true' para testes

  const authInstance = getAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      const currentUser = authInstance.currentUser;
      if (currentUser) {
        // Aqui você pode buscar informações adicionais do usuário se necessário
      }
    };

    const fetchUsers = async () => {
      setUsersLoading(true);
      setUsersError(null);
      try {
        const currentUser = authInstance.currentUser;
        if (!currentUser) {
          setUsersError('Usuário não autenticado.');
          setUsersLoading(false);
          return;
        }

        const idToken = await currentUser.getIdToken();

        // Verificação temporária para simular admin
        if (!isAdmin) {
          setUsersError('Acesso negado. Usuário não é administrador.');
          setUsersLoading(false);
          return;
        }

        // Se o backend estivesse integrado, a requisição seria feita aqui
        // Por enquanto, vamos mockar alguns usuários com 'Ultimo Login'
        const mockUsers = [
          { uid: '1', displayName: 'Alice', lastLogin: '2024-04-20 14:35', profile: 'Administrador' },
          { uid: '2', displayName: 'Bruno', lastLogin: '2024-04-18 09:22', profile: 'Gerente' },
          { uid: '3', displayName: 'Carla', lastLogin: '2024-04-19 16:45', profile: 'Atendente' },
          // Adicione mais usuários conforme necessário
        ];

        // Simular atraso da requisição
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Ordenar alfabeticamente pelo nome
        const sortedUsers = mockUsers.sort((a, b) =>
          a.displayName.localeCompare(b.displayName)
        );

        setUsers(sortedUsers);
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        setUsersError('Erro ao buscar usuários. Por favor, tente novamente.');
      } finally {
        setUsersLoading(false);
      }
    };

    fetchUserData();

    if (isAdmin) {
      fetchUsers();
    } else {
      setUsersError('Acesso negado. Usuário não é administrador.');
      setUsersLoading(false);
    }
  }, [authInstance, isAdmin]);

  // Função para cadastrar um novo usuário (Admin)
  const handleCreateUser = async () => {
    if (!newUserName.trim() || !newUserPassword.trim()) {
      setNewUserMessage({ type: 'error', text: 'Por favor, preencha todos os campos obrigatórios.' });
      return;
    }

    setNewUserLoading(true);
    setNewUserMessage(null);

    try {
      const currentUser = authInstance.currentUser;

      if (!currentUser) {
        setNewUserMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
        setNewUserLoading(false);
        return;
      }

      // Obter o ID Token para autenticar a requisição no backend
      const idToken = await currentUser.getIdToken();

      // Se o backend estivesse integrado, a requisição seria feita aqui
      // Por enquanto, vamos mockar a criação do usuário
      const newUser = {
        uid: Date.now().toString(),
        displayName: newUserName,
        lastLogin: 'Nunca', // Como não há backend, definimos como 'Nunca'
        profile: newUserProfile,
      };

      // Simular atraso da requisição
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Atualizar a lista de usuários e ordenar alfabeticamente
      const updatedUsers = [...users, newUser].sort((a, b) =>
        a.displayName.localeCompare(b.displayName)
      );
      setUsers(updatedUsers);
      setNewUserMessage({ type: 'success', text: 'Novo usuário criado com sucesso!' });
      // Limpar os campos após o sucesso
      setNewUserName('');
      setNewUserPassword('');
      setNewUserProfile('Atendente'); // Resetar para o valor padrão
      setShowCreateForm(false);
    } catch (error) {
      console.error('Erro ao criar novo usuário:', error);
      let errorText = 'Erro ao criar novo usuário. Por favor, tente novamente.';
      if (error.response && error.response.data && error.response.data.error) {
        errorText = error.response.data.error;
      }
      setNewUserMessage({ type: 'error', text: errorText });
    } finally {
      setNewUserLoading(false);
    }
  };

  // Função para iniciar a edição de um usuário
  const startEditingUser = (user) => {
    setEditingUser(user);
    setEditName(user.displayName || '');
    setEditPassword(''); // Deixe a senha em branco
    setEditProfile(user.profile || 'Atendente'); // Preencher o perfil atual
    setEditMessage(null);
  };

  // Função para cancelar a edição
  const cancelEditing = () => {
    setEditingUser(null);
    setEditName('');
    setEditPassword('');
    setEditProfile('Atendente');
    setEditMessage(null);
  };

  // Função para atualizar um usuário específico
  const handleUpdateUser = async () => {
    if (!editName.trim()) {
      setEditMessage({ type: 'error', text: 'Por favor, preencha o nome.' });
      return;
    }

    setEditLoading(true);
    setEditMessage(null);

    try {
      const currentUser = authInstance.currentUser;

      if (!currentUser) {
        setEditMessage({ type: 'error', text: 'Usuário não autenticado. Por favor, faça login.' });
        setEditLoading(false);
        return;
      }

      // Obter o ID Token para autenticar a requisição no backend
      const idToken = await currentUser.getIdToken();

      // Se o backend estivesse integrado, a requisição seria feita aqui
      // Por enquanto, vamos mockar a atualização do usuário
      const updatedUser = {
        ...editingUser,
        displayName: editName,
        profile: editProfile,
        // 'lastLogin' poderia ser atualizado conforme necessário
      };

      if (editPassword.trim()) {
        // Lógica para atualizar a senha no backend
        // Como estamos mockando, apenas atualizamos localmente
      }

      // Simular atraso da requisição
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Atualizar a lista de usuários
      const updatedUsers = users
        .map((user) => (user.uid === editingUser.uid ? updatedUser : user))
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      setUsers(updatedUsers);
      setEditMessage({ type: 'success', text: 'Usuário atualizado com sucesso!' });
      // Cancelar a edição
      cancelEditing();
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      let errorText = 'Erro ao atualizar usuário. Por favor, tente novamente.';
      if (error.response && error.response.data && error.response.data.error) {
        errorText = error.response.data.error;
      }
      setEditMessage({ type: 'error', text: errorText });
    } finally {
      setEditLoading(false);
    }
  };

  // Função para filtrar usuários com base na busca
  const filteredUsers = users.filter((user) =>
    user.displayName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      style={{
        backgroundColor: '#242424',
        color: '#fff',
        padding: '30px',
        borderRadius: '12px',
        width: '100%',
        maxWidth: '1000px', // Aumentado para acomodar listagem e formulários
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px', // Espaçamento entre seções
      }}
    >
      {/* Cabeçalho com botão "Adicionar" */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Título removido conforme solicitado */}
        <div></div>
        <button
          onClick={() => setShowCreateForm(true)}
          style={{
            backgroundColor: '#242424',
            color: '#e5e7eb',
            padding: '10px 20px',
            border: '1px solid #555', // Adicionado border
            borderRadius: '6px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '16px',
            transition: 'background-color 0.3s, border 0.3s',
          }}
          aria-label="Adicionar Usuário"
        >
          <Plus size={20} />
          Adicionar
        </button>
      </div>

      {/* Título "Listagem de Usuários" */}
      <div>
        <h3 style={{ textAlign: 'center' }}>Listagem de Usuários</h3>
      </div>

      {/* Campo de Busca */}
      <div style={{ textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Buscar usuário..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px 15px',
            borderRadius: '6px',
            border: '1px solid #555',
            width: '80%',
            maxWidth: '400px',
            fontSize: '16px',
          }}
        />
      </div>

      {/* Seção de Listagem de Usuários */}
      <div>
        {/* Título "Listagem de Usuários" já adicionado acima */}
        {usersLoading ? (
          <div style={{ textAlign: 'center' }}>Carregando usuários...</div>
        ) : usersError ? (
          <div style={{ textAlign: 'center', color: '#ff6b6b' }}>{usersError}</div>
        ) : (
          <div>
            {filteredUsers.length === 0 ? (
              <div style={{ textAlign: 'center' }}>Nenhum usuário encontrado.</div>
            ) : (
              <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyle}>Usuário</th>
                    <th style={tableHeaderStyle}>Perfil de Acesso</th>
                    <th style={tableHeaderStyle}>Último Login</th> {/* Último Login como última coluna */}
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map((user) => (
                    <tr
                      key={user.uid}
                      style={tableRowStyle}
                      onClick={() => startEditingUser(user)}
                      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#3a3a3a')}
                      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'transparent')}
                      aria-label={`Selecionar usuário ${user.displayName}`}
                    >
                      <td style={tableCellStyle}>{user.displayName || 'Sem Nome'}</td>
                      <td style={tableCellStyle}>{user.profile || 'Atendente'}</td>
                      <td style={tableCellStyle}>{user.lastLogin || 'Nunca'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>

      {/* Modal para Cadastro de Novo Usuário (Admin) */}
      {showCreateForm && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            {/* Botão "X" para fechar a modal */}
            <button
              onClick={() => {
                setShowCreateForm(false);
                setNewUserName('');
                setNewUserPassword('');
                setNewUserProfile('Atendente');
                setNewUserMessage(null);
              }}
              style={closeButtonStyle}
              aria-label="Fechar cadastro"
            >
              <X size={20} />
            </button>

            <h2 style={{ fontSize: '24px', textAlign: 'center', marginBottom: '20px' }}>Cadastro de Novo Usuário</h2>

            <div>
              <label htmlFor="new-user-name" style={{ display: 'block', marginBottom: '8px', fontSize: '18px' }}>
                Nome
              </label>
              <input
                id="new-user-name"
                type="text"
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
                placeholder="Nome do novo usuário"
                style={inputStyle}
              />
            </div>

            <div>
              <label htmlFor="new-user-profile" style={{ display: 'block', marginBottom: '8px', fontSize: '18px' }}>
                Perfil de Acesso
              </label>
              <select
                id="new-user-profile"
                value={newUserProfile}
                onChange={(e) => setNewUserProfile(e.target.value)}
                style={inputStyle}
              >
                <option value="Administrador">Administrador</option>
                <option value="Gerente">Gerente</option>
                <option value="Atendente">Atendente</option>
              </select>
            </div>

            <div>
              <label htmlFor="new-user-password" style={{ display: 'block', marginBottom: '8px', fontSize: '18px' }}>
                Senha
              </label>
              <input
                id="new-user-password"
                type="password"
                value={newUserPassword}
                onChange={(e) => setNewUserPassword(e.target.value)}
                placeholder="Senha do novo usuário"
                style={inputStyle}
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px' }}>
              <button
                onClick={handleCreateUser}
                style={saveButtonStyle}
                disabled={newUserLoading}
                aria-label="Cadastrar novo usuário"
              >
                <Save size={16} />
                {newUserLoading ? 'Cadastrando...' : 'Cadastrar'}
              </button>
            </div>

            {newUserMessage && (
              <div
                style={{
                  marginTop: '10px',
                  color: newUserMessage.type === 'error' ? '#ff6b6b' : '#4caf50',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
              >
                {newUserMessage.text}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Seção de Edição de Usuário */}
      {editingUser && (
        <div style={modalOverlayStyle}>
          <div style={modalContentStyle}>
            {/* Botão "X" para fechar a modal */}
            <button
              onClick={() => {
                cancelEditing();
                setEditName('');
                setEditPassword('');
                setEditProfile('Atendente');
                setEditMessage(null);
              }}
              style={closeButtonStyle}
              aria-label="Fechar edição"
            >
              <X size={20} />
            </button>

            <h2 style={{ fontSize: '24px', textAlign: 'center', marginBottom: '20px' }}>Edição de Usuário</h2>

            <div>
              <label htmlFor="edit-user-name" style={{ display: 'block', marginBottom: '8px', fontSize: '18px' }}>
                Nome
              </label>
              <input
                id="edit-user-name"
                type="text"
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                placeholder="Nome do usuário"
                style={inputStyle}
              />
            </div>

            <div>
              <label htmlFor="edit-user-profile" style={{ display: 'block', marginBottom: '8px', fontSize: '18px' }}>
                Perfil de Acesso
              </label>
              <select
                id="edit-user-profile"
                value={editProfile}
                onChange={(e) => setEditProfile(e.target.value)}
                style={inputStyle}
              >
                <option value="Administrador">Administrador</option>
                <option value="Gerente">Gerente</option>
                <option value="Atendente">Atendente</option>
              </select>
            </div>

            <div>
              <label htmlFor="edit-user-password" style={{ display: 'block', marginBottom: '8px', fontSize: '18px' }}>
                Senha
              </label>
              <input
                id="edit-user-password"
                type="password"
                value={editPassword}
                onChange={(e) => setEditPassword(e.target.value)}
                placeholder="Nova senha (deixe em branco para não alterar)"
                style={inputStyle}
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px' }}>
              <button
                onClick={handleUpdateUser}
                style={saveButtonStyle}
                disabled={editLoading}
                aria-label="Atualizar usuário"
              >
                <Save size={16} />
                {editLoading ? 'Atualizando...' : 'Atualizar'}
              </button>
            </div>

            {editMessage && (
              <div
                style={{
                  marginTop: '10px',
                  color: editMessage.type === 'error' ? '#ff6b6b' : '#4caf50',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
              >
                {editMessage.text}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

// Estilos reutilizáveis
const tableHeaderStyle = {
  borderBottom: '2px solid #444654',
  padding: '10px',
  textAlign: 'left',
};

const tableRowStyle = {
  borderBottom: '1px solid #444654',
  cursor: 'pointer', // Indica que a linha é clicável
  transition: 'background-color 0.3s',
};

const tableCellStyle = {
  padding: '10px',
};

const createButtonStyle = {
  backgroundColor: '#242424',
  padding: '10px 20px',
  color: '#e5e7eb',
  borderRadius: '6px',
  cursor: 'pointer',
  border: '1px solid #555',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  transition: 'background-color 0.3s',
};

const saveButtonStyle = {
  backgroundColor: '#242424',
  padding: '10px 20px',
  color: '#e5e7eb',
  borderRadius: '6px',
  cursor: 'pointer',
  border: '1px solid #555',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  transition: 'background-color 0.3s, border 0.3s',
};

const inputStyle = {
  backgroundColor: '#333',
  color: '#fff',
  padding: '12px',
  borderRadius: '6px',
  border: '1px solid #555',
  width: '100%',
  fontSize: '16px',
};

// Estilos para os modais
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: '#242424',
  padding: '30px',
  borderRadius: '12px',
  width: '90%',
  maxWidth: '500px',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  position: 'relative', // Para posicionar o botão X
};

// Adicionando a definição do closeButtonStyle
const closeButtonStyle = {
  position: 'absolute',
  top: '15px',
  right: '15px',
  backgroundColor: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
};

export default UserConfig;
