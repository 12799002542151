// src/components/TemplateConfig.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../firebase';

const TemplateConfig = ({ onBack }) => {
  const [templates, setTemplates] = useState([]);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [updateTemplateId, setUpdateTemplateId] = useState('');
  const [updateTemplateName, setUpdateTemplateName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Função para obter o token de autenticação do usuário
  const getAuthToken = async () => {
    const user = auth.currentUser;
    if (user) {
      return await user.getIdToken();
    }
    throw new Error('Usuário não autenticado');
  };

  // Função para listar todos os templates
  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const token = await getAuthToken();
      const response = await axios.get('/api/get-templates', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTemplates(response.data.templates);
      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
      setLoading(false);
    }
  };

  // Função para adicionar um novo template
  const addTemplate = async (e) => {
    e.preventDefault();
    if (!newTemplateName.trim()) {
      setError('O nome do template não pode estar vazio.');
      return;
    }
    try {
      setLoading(true);
      const token = await getAuthToken();
      const response = await axios.post(
        '/api/add-template',
        { templateName: newTemplateName },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      setTemplates([...templates, response.data.template]);
      setNewTemplateName('');
      setError(null);
      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
      setLoading(false);
    }
  };

  // Função para atualizar um template existente
  const updateTemplate = async (e) => {
    e.preventDefault();
    if (!updateTemplateId || !updateTemplateName.trim()) {
      setError('ID do template e novo nome são obrigatórios.');
      return;
    }
    try {
      setLoading(true);
      const token = await getAuthToken();
      await axios.post(
        '/api/update-template',
        {
          templateId: updateTemplateId,
          newTemplateName: updateTemplateName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      // Atualizar a lista local de templates
      setTemplates(
        templates.map((template) =>
          template.id === updateTemplateId
            ? { ...template, name: updateTemplateName }
            : template
        )
      );
      setUpdateTemplateId('');
      setUpdateTemplateName('');
      setError(null);
      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.error || err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="template-config-container" style={{ padding: '20px', maxWidth: '800px', width: '100%' }}>
      <h2>Configuração de Templates</h2>

      {error && <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
      {loading && <div className="loading-message" style={{ color: 'blue', marginBottom: '10px' }}>Carregando...</div>}

      {/* Botão de Voltar */}
      <button
        onClick={onBack}
        style={{
          background: 'none',
          border: 'none',
          color: '#e9edef',
          cursor: 'pointer',
          marginBottom: '20px',
        }}
      >
        ← Voltar
      </button>

      {/* Lista de Templates */}
      <div className="templates-list" style={{ marginBottom: '30px' }}>
        <h3>Templates Existentes</h3>
        {templates.length === 0 ? (
          <p>Nenhum template encontrado.</p>
        ) : (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {templates.map((template) => (
              <li key={template.id} style={{ background: '#333', padding: '10px', borderRadius: '4px', marginBottom: '5px' }}>
                <strong>{template.name}</strong> (ID: {template.id})
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Formulário para Adicionar Template */}
      <div className="add-template-form" style={{ marginBottom: '30px' }}>
        <h3>Adicionar Novo Template</h3>
        <form onSubmit={addTemplate} style={{ display: 'flex', gap: '10px' }}>
          <input
            type="text"
            placeholder="Nome do Template"
            value={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
            required
            style={{
              flex: 1,
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#141414',
              color: '#e9edef',
            }}
          />
          <button
            type="submit"
            disabled={loading}
            style={{
              padding: '10px 20px',
              backgroundColor: '#28a745',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Adicionar
          </button>
        </form>
      </div>

      {/* Formulário para Atualizar Template */}
      <div className="update-template-form" style={{ marginBottom: '30px' }}>
        <h3>Atualizar Template Existente</h3>
        <form onSubmit={updateTemplate} style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
          <select
            value={updateTemplateId}
            onChange={(e) => setUpdateTemplateId(e.target.value)}
            required
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#141414',
              color: '#e9edef',
            }}
          >
            <option value="">Selecione um Template</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Novo Nome do Template"
            value={updateTemplateName}
            onChange={(e) => setUpdateTemplateName(e.target.value)}
            required
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              backgroundColor: '#141414',
              color: '#e9edef',
            }}
          />
          <button
            type="submit"
            disabled={loading}
            style={{
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Atualizar
          </button>
        </form>
      </div>
    </div>
  );
};

export default TemplateConfig;
